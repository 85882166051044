import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/common.css";
import Video from "video.js";
import "video.js/dist/video-js.min.css"
Vue.prototype.$video = Video


import "./assets/js/size.js";

Vue.config.productionTip = false
Vue.prototype.basUrl = 'https://cdn.rongdeji.cn/'
/* 发邮件功能 */
/* qq2671771031@163.com */
// 用户名
Vue.prototype.UsernameEmail = 'rongdejikejian@163.com'
// 密钥(开启stmp，授权码) 邮箱的SMTP服务默认是关闭的，先开启，开启的时候会生成一个授权码。
Vue.prototype.PasswordEmail = 'YGFDAVZVRGIOCBXC'
// 荣德基授权码 YGFDAVZVRGIOCBXC
// fhq授权码 AIQBHXXXLNVPWMUO
// 从谁发出(和用户名相同)
Vue.prototype.FromEmail = 'rongdejikejian@163.com'
/* end */
//引入mint-ui
import MINT from 'mint-ui'
import 'mint-ui/lib/style.css'
Vue.use(MINT)

/*复制功能*/
import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

/* 百度统计  */
router.afterEach( ( to, from, next ) => {
    setTimeout(()=>{
		/* rongdeji */
            var _hmt = _hmt || [];
            (function() {
            	var hm = document.createElement("script");
            	hm.src = "https://hm.baidu.com/hm.js?9e9ac00efe7cfca4278ab0382f10e68e";
            	var s = document.getElementsByTagName("script")[0];
            	s.parentNode.insertBefore(hm, s);
            })();
		/* bulici */
			// var _hmt = _hmt || [];
			// (function() {
			//   var hm = document.createElement("script");
			//   hm.src = "https://hm.baidu.com/hm.js?87c86563b4369888f86cda8b0c4d3449";
			//   var s = document.getElementsByTagName("script")[0]; 
			//   s.parentNode.insertBefore(hm, s);
			// })();
    },0);
} );


import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
//引入富文本内容样式
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
