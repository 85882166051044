import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from "../views/Main.vue"

Vue.use(VueRouter)

const routes = [{
		path: "/",
		name: "main",
		component: Main,
		children: [{
				path: "/",
				component: () =>
					import( /* webpackChunkName: "home" */ "../views/Home.vue"),
				meta: {
					keepAlive: true
				}
			},
			{
				path: "/books",
				name: "books",
				component: () =>
					import( /* webpackChunkName: "books" */ "../views/Books.vue"),
				meta: {
					keepAlive: false
				}
			},
			{
				path: "/down",
				name: "down",
				component: () =>
					import( /* webpackChunkName: "down" */ "../views/Down.vue"),
				meta: {
					keepAlive: true
				}
			},
			{
				path: "/user",
				name: "user",
				component: () =>
					import( /* webpackChunkName: "user" */ "../views/User.vue"),
				meta: {
					keepAlive: false
				}
			}
		]
	},
	//查看外部链接地址
	{
		path: '/lookWai',
		name: 'lookWai',
		component: () =>
			import( /* webpackChunkName: "grade" */ '../views/lookWai.vue'),
		meta: {
			keepAlive: false
		}
	},
	//接受二维码的扫描地址

	{
		path: '/erwe',
		name: 'erwe',
		component: () =>
			import( /* webpackChunkName: "grade" */ '../views/erwe.vue'),
		meta: {
			keepAlive: true
		}
	},
	//关于我们
	{
		path: '/aboutUs',
		name: 'aboutUs',
		component: () =>
			import( /* webpackChunkName: "grade" */ '../views/About.vue'),
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/userAgreement',
		name: 'userAgreement',
		component: () =>
			import( /* webpackChunkName: "grade" */ '../views/userAgreement.vue'),
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/privacy',
		name: 'privacy',
		component: () =>
			import( /* webpackChunkName: "grade" */ '../views/privacy.vue'),
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/grade',
		name: 'grade',
		component: () =>
			import( /* webpackChunkName: "grade" */ '../views/Grade.vue'),
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/message',
		name: 'message',
		component: () =>
			import( /* webpackChunkName: "message" */ '../views/Message.vue'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/messageDetail',
		name: 'messageDetail',
		component: () =>
			import( /* webpackChunkName: "message" */ '../views/messageDetail.vue'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/teacher',
		name: 'teacher',
		component: () =>
			import( /* webpackChunkName: "teacher" */ '../views/Teacher.vue'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/teachercont',
		name: 'teachercont',
		component: () =>
			import( /* webpackChunkName: "demandcont" */ '../views/TeacherCont.vue'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/english',
		name: 'english',
		component: () =>
			import( /* webpackChunkName: "english" */ '../views/English.vue'),
		meta: {
			keepAlive: true,
		}
	},
	{
		path: '/chinese',
		name: 'chinese',
		component: () =>
			import( /* webpackChunkName: "chinese" */ '../views/Chinese.vue'),
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/testpaper',
		name: 'testpaper',
		component: () =>
			import( /* webpackChunkName: "testpaper" */ '../views/TestPaper.vue'),
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/demand',
		name: 'demand',
		component: () =>
			import( /* webpackChunkName: "demand" */ '../views/Demand.vue'),
		meta: {
			keepAlive: false
		}
	},
	/* 搜索详情 */
	{
		path: '/search',
		name: 'search',
		component: () =>
			import( /* webpackChunkName: "demand" */ '../views/search.vue'),
		meta: {
			keepAlive: false
		}
	},
	/* end */
	{
		path: '/demandcont',
		name: 'demandcont',
		component: () =>
			import( /* webpackChunkName: "demandcont" */ '../views/DemandCont.vue'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/unitsummary',
		name: 'unitsummary',
		component: () =>
			import( /* webpackChunkName: "unitsummary" */ '../views/UnitSummary.vue'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/unitsummaryWorld',
		name: 'unitsummaryWorld',
		component: () =>
			import( /* webpackChunkName: "unitsummaryvideo" */ '../views/unitsummaryWorld.vue'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/unitsummaryvideo',
		name: 'unitsummaryvideo',
		component: () =>
			import( /* webpackChunkName: "unitsummaryvideo" */ '../views/UnitSummaryVideo.vue'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/unitsummaryAudio',
		name: 'unitsummaryAudio',
		component: () =>
			import( /* webpackChunkName: "unitsummaryvideo" */ '../views/unitsummaryAudio.vue'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/unitsummaryZip',
		name: 'unitsummaryZip',
		component: () =>
			import( /* webpackChunkName: "unitsummaryvideo" */ '../views/unitsummaryZip.vue'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/unitsummaryTu',
		name: 'unitsummaryTu',
		component: () =>
			import( /* webpackChunkName: "unitsummaryvideo" */ '../views/unitsummaryTu.vue'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/unitsummaryPdf',
		name: 'unitsummaryPdf',
		component: () =>
			import( /* webpackChunkName: "unitsummaryvideo" */ '../views/unitsummaryPdf.vue'),
		meta: {
			keepAlive: false
		}
	},

	{
		path: '/mybooks',
		name: 'mybooks',
		component: () =>
			import( /* webpackChunkName: "mybooks" */ '../views/MyBooks.vue')
	},
	{
		path: '/myactivity',
		name: 'myactivity',
		component: () =>
			import( /* webpackChunkName: "myactivity" */ '../views/MyActivity.vue'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/myrecord',
		name: 'myrecord',
		component: () =>
			import( /* webpackChunkName: "myrecord" */ '../views/MyRecord.vue'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/myrecordcont',
		name: 'myrecordcont',
		component: () =>
			import( /* webpackChunkName: "myrecordcont" */ '../views/MyRecordCont.vue'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/myrecordedit',
		name: 'myrecordedit',
		component: () =>
			import( /* webpackChunkName: "myrecordedit" */ '../views/MyRecordEdit.vue'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/myaccount',
		name: 'myaccount',
		component: () =>
			import( /* webpackChunkName: "myaccount" */ '../views/MyAccount.vue'),
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/mypersonal',
		name: 'mypersonal',
		component: () =>
			import( /* webpackChunkName: "mypersonal" */ '../views/MyPersonal.vue'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/myaddress',
		name: 'myaddress',
		component: () =>
			import( /* webpackChunkName: "myaddress" */ '../views/MyAddress.vue'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/myaddaddress',
		name: 'myaddaddress',
		component: () =>
			import( /* webpackChunkName: "myaddaddress" */ '../views/MyAddAddress.vue'),
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/editAddress',
		name: 'editAddress',
		component: () =>
			import( /* webpackChunkName: "myaddaddress" */ '../views/editAddress.vue'),
		meta: {
			keepAlive: false
		}
	},

	{
		path: '/downcont',
		name: 'downcont',
		component: () =>
			import( /* webpackChunkName: "downcont" */ '../views/DownCont.vue'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/about',
		name: 'about',
		component: () =>
			import( /* webpackChunkName: "about" */ '../views/About.vue')
	},
	// 测试 记得删
	{
		path: '/new_file',
		name: 'new_file',
		component: () =>
			import( /* webpackChunkName: "about" */ '../views/new_file.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savePosition) {
		// to：要进入的目标路由对象，到哪里去
		// from：离开的路由对象，哪里来
		// savePosition：会记录滚动条的坐标，点击前进/后退的时候记录值{x:?,y:?}
		if(savePosition) {
			return savePosition;
		} else {
			return {
				x: 0,
				y: 0
			};
		}
	}
})
router.beforeEach((to, from, next) => {
	next();
})

export default router