<template>
  <div id="main">
    <!--<VHeader/>-->
    <router-view/>
    <VFooter/>
  </div>
</template>
<script>
// import VHeader from "@/components/V-Header.vue";
import VFooter from "@/components/V-Footer.vue";
export default {
  components: {
    VFooter
  },
  methods: {}
};
</script>

