<template>
<div>
  <div class="blian5"></div>
  <footer>
    <ul class="clearfix">
      <li>
        <router-link to="/" exact>
            <span>
              <img src="../assets/images/index.png" class="exact"/>
              <img src="../assets/images/index_on.png" class="exact_on"/>
            </span>
            <p>首页</p>
        </router-link>
      </li>
      <li>
        <router-link to="/books">
            <span>              
              <img src="../assets/images/books.png" class="exact"/>
              <img src="../assets/images/books_on.png" class="exact_on"/>
            </span>
            <p>书架</p>
        </router-link>
      </li>
      <!--<li>
        <router-link to="/down">
            <span>              
              <img src="../assets/images/down.png" class="exact"/>
              <img src="../assets/images/down_on.png" class="exact_on"/>
            </span>
            <p>下载中心</p>
        </router-link>
      </li>-->
      <li>
        <router-link to="/user">
            <span>              
              <img src="../assets/images/user.png" class="exact"/>
              <img src="../assets/images/user_on.png" class="exact_on"/>
            </span>
            <p>我的</p>
        </router-link>
      </li>
    </ul>
  </footer>
</div>
</template>
<style lang="scss" scoped>

</style>
<script>
export default {
  name: "v-footer",
  data: () => {
    return {
      
    };
  },  
  methods: {},
  mounted() {}
};
</script>
